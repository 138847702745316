<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">业务干预</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">退款管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">退款记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="退款时间" class="searchboxItem ci-full">
              <span class="itemLabel">退款时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="refundTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="订单号" class="searchboxItem ci-full">
              <span class="itemLabel">订单号:</span>
              <el-input
                v-model="orderSn"
                type="text"
                size="small"
                clearable
                placeholder="请输入订单号"
              />
            </div>
            <div title="买家手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">买家手机号:</span>
              <el-input
                v-model="userPhone"
                type="text"
                size="small"
                clearable
                placeholder="请输入买家手机号"
              />
            </div>

            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="refundRecordExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <h4 style="padding: 8px 10px">退款金额汇总:{{ totalMoney || 0 }}元</h4>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="订单编号"
                align="center"
                prop="orderSn"
                show-overflow-tooltip
                min-width="150"
              />

              <el-table-column
                label="商品类型"
                align="center"
                prop="productType"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="买家手机号"
                align="center"
                prop="mobile"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="订单金额"
                align="center"
                prop="orderMoney"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="实付金额"
                align="center"
                prop="paymentMoney"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <span style="color: red">{{ scope.row.paymentMoney }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="支付方式"
                align="center"
                prop="paymentMethod"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("ORDER_METHOD", scope.row.paymentMethod) }}
                </template>
              </el-table-column>

              <el-table-column
                label="订单状态"
                align="center"
                prop="orderState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ getOrderState(scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column
                label="支付时间"
                align="center"
                width="140"
                prop="payTime"
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.payTime | moment }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="退款时间"
                align="center"
                width="140"
                prop="refundTime"
              >
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.refundTime | moment }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="RefundDetail(scope.row.refundId)"
                    >查看详情</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      ref="detailsform"
      :model="detailsform"
      prop="detailsform"
      :visible.sync="dialogBus"
      title="订单详情"
      width="40%"
      top="2%"
      center
      @close="closeCode"
    >
      <div class="orderDetail">
        <div :class="ruleForm.orderStatus != 1 ? 'order_err' : 'order_success'">
          <div style="display: flex; align-items: center">
            <i
              :class="
                ruleForm.orderStatus != 1 ? 'el-icon-error' : 'el-icon-success'
              "
              style="font-size: 16px"
            ></i>
            <span class="orderStatus">{{
              getOrderState(ruleForm.orderStatus)
            }}</span>
          </div>
        </div>
        <div class="order_form">
          <h4>退款信息</h4>
          <el-divider class="divider"></el-divider>
          <div class="df flexwp orderBox">
            <div class="df">
              <span>退款时间：</span>
              <span>{{ ruleForm.refundTime | moment }}</span>
            </div>
            <div class="df">
              <span>退款原因：</span>

              <span>{{
                $setDictionary("PAY_REFUND_REASON", ruleForm.refundReasonCode)
              }}</span>
            </div>
            <div style="width: 100%; padding: 8px 0">
              <span>退款备注：</span>
              <span style="    width: 93%;margin: 0 auto;display: block;">{{ ruleForm.refundRemarks || "--" }}</span>
            </div>
          </div>
          <h4>订单信息</h4>
          <el-divider class="divider"></el-divider>
          <div class="df flexwp orderBox">
            <div class="df">
              <span>订单编号：</span>
              <span>{{ ruleForm.orderSn }}</span>
            </div>
            <div class="df">
              <span>下单方式：</span>
              <span>{{
                ruleForm.orderPhone
              }}</span>
            </div>
            <div class="df">
              <span>下单时间：</span>
              <span>{{ ruleForm.createTime }}</span>
            </div>
            <div class="df">
              <span>支付方式：</span>
              <span>{{$setDictionary("ORDER_METHOD",ruleForm.paymentMethod)}}</span>
              <!-- <span v-if="ruleForm.paymentMethod == 1">苹果内部支付</span>
              <span v-else-if="ruleForm.paymentMethod == 2"
                >银联支付宝支付</span
              >
              <span v-else-if="ruleForm.paymentMethod == 3">银联微信支付</span>
              <span v-else-if="ruleForm.paymentMethod == 4">微信支付</span>
              <span v-else-if="ruleForm.paymentMethod == 9">积分支付</span>
              <span v-else>--</span> -->
            </div>
            <div class="df">
              <span>支付时间：</span>
              <span>{{ ruleForm.payTime || "--" }}</span>
            </div>
            <div class="df">
              <span>支付单号：</span>
              <span>{{ ruleForm.thirdPartPaymentNumber || "--" }}</span>
            </div>
            <div class="df">
              <span
                >{{
                  "实付金额"
                }}：</span
              >
              <span>{{ ruleForm.paymentMoney || "--" }}</span>
            </div>
          </div>
          <h4>买家信息</h4>
          <el-divider></el-divider>
          <div class="df flexwp orderBox">
            <div class="df">
              <span>买家姓名：</span>
              <span>{{ ruleForm.userName }}</span>
            </div>
            <div class="df">
              <span>手机号：</span>
              <span>{{ ruleForm.mobile }}</span>
            </div>
          </div>
          <h4>商品信息</h4>
          <el-divider></el-divider>
          <el-table
            ref="multipleTable"
            :data="ruleForm.productList"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            stripe
            :header-cell-style="tableHeader"
          >
            <el-table-column
              label="课程名称"
              align="left"
              show-overflow-tooltip
              prop="productName"
            />
            <el-table-column
              label="价格"
              align="left"
              show-overflow-tooltip
              prop="productPrice"
            />
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
import { Message } from "element-ui";

export default {
  name: "businessIntervention/refundRecord",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      refundTime: "", //退款时间
      orderSn: "", //订单号
      userPhone: "", //手机号
      dialogBus: false,
      ruleForm: {},
      totalMoney: "", //退款总金额
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getTableHeight();
  },
  methods: {
    //订单状态转换
    getOrderState(orderState) {
      switch (orderState) {
        case "10":
          return "未支付";
        case "20":
          return "已完成";
        case "30":
          return "退款进行中";
        case "40":
          return "退款失败";
        case "50":
          return "有退款";
        case "60":
          return "已退款";
        default: {
          return "--";
        }
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.orderSn) {
        params.orderSn = this.orderSn;
      }
      if (this.userPhone) {
        params.userPhone = this.userPhone;
      }
      if (this.refundTime) {
        params.createTime = this.refundTime[0];
        params.endTime = this.refundTime[1];
      }
      this.doFetch(
        {
          url: "/order/main/order-main/refund/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
      this.getTotalMoney();
    },
    //退款总金额
    getTotalMoney() {
      const params = {};
      if (this.orderSn) {
        params.orderSn = this.orderSn;
      }
      if (this.userPhone) {
        params.userPhone = this.userPhone;
      }
      if (this.refundTime) {
        params.createTime = this.refundTime[0];
        params.endTime = this.refundTime[1];
      }
      this.$post(
        "/order/main/order-main/refund/totalMoney",
        params,
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          this.totalMoney = res.data;
        }
      });
    },
    RefundDetail(refundId) {
      this.$post(
        "/order/main/order-main/refund/detail",
        { refundId },
        3000,
        true,
        2
      ).then((res) => {
        this.dialogBus = true;
        this.ruleForm = {
          ...res.data,
        };
      });
    },
    //导出
    refundRecordExport() {
        const params = {};
      if (this.orderSn) {
        params.orderSn = this.orderSn;
      }
      if (this.userPhone) {
        params.userPhone = this.userPhone;
      }
      if (this.refundTime) {
        params.createTime = this.refundTime[0];
        params.endTime = this.refundTime[1];
      }
      this.$post('/order/main/order-main/refund/listExport',params,3000,true,2).then(res => {
            if (res.status == "0") {
              let list = [];
              list.push(res.data);
              for (let item of list) {
                console.log(item);
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(re.message);
            }
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == "true") {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style>
.confirmButton-feifan {
  background-color: #5c6be8 !important;
  color: white;
  border: none;
}
</style>
<style lang="less" scoped>
.orderDetail {
  .orderStatus {
    font-weight: 800;
    font-size: 16px;
    margin-left: 10px;
  }
  .order_err {
    padding: 10px;
    background-color: #7f7f7f;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_success {
    padding: 10px;
    background-color: #70b602;
    // border: 1px solid rgb(255, 204, 0);
    color: #fff;
  }
  .order_form {
    margin-top: 15px;
    div.df {
      padding: 8px 0;
      span:first-child {
        width: 6rem;
        text-align: right;
        margin-right: 8px;
      }
    }
  }
}
.divider {
  margin: 12px 0;
}
.orderBox {
  div {
    width: 50%;
    align-items: center;
  }
}
</style>
